<template>
  <user-mutation>
    <template v-slot="{ isSaving, updateUserAccount }">
      <div class="settings-account-tab">
        <div class="edit-account">
          <h2>User Account Info</h2>

          <user-query>
            <template v-slot="{ isLoading, data: user }">
              <edit-contact-form
                ref="editContact"
                :is-loading="isSaving"
                class="mt-10 border-bottom pb-10"
                :contact="user"
                show-submit
                @submit="updateUserAccount"
              >
                <div
                  v-if="user && user.roles"
                  slot="bottom"
                  class="mt-6 text-md"
                >
                  <div class="flex items-center">
                    <div class="font-bold">
                      User Roles
                    </div>
                    <el-tooltip effect="light" placement="right" class="ml-3">
                      <template #content>
                        <div class="w-40">
                          <a
                            href="https://intercom.help/flytedesk/articles/3102562-assigning-team-roles"
                            target="_blank"
                            >Learn more about user permission roles
                          </a>
                        </div>
                      </template>
                      <icon :icon="infoIcon" class="dark-silver icon-md" />
                    </el-tooltip>
                  </div>
                  <div class="user-roles">
                    <div
                      v-for="role in user.roles"
                      :key="'user-role-' + role.id"
                    >
                      {{ role.name }}
                    </div>
                  </div>
                </div>
              </edit-contact-form>
            </template>
          </user-query>
        </div>

        <edit-password
          ref="editPassword"
          :is-loading="isSaving"
          class="mt-6"
          @submit="onEditPassword($event, updateUserAccount)"
        />
      </div>
    </template>
  </user-mutation>
</template>

<script>
import EditContactForm from '@/components/Core/Settings/EditContactForm';
import EditPassword from '@/components/Core/Settings/EditPassword';
import { infoCircled as infoIcon } from '@/vendor/icons';
import { UserMutation } from '@/components/Mutations';
import { UserQuery } from '@/components/Queries';

export default {
  components: {
    EditContactForm,
    EditPassword,
    UserMutation,
    UserQuery
  },
  data() {
    return {
      infoIcon
    };
  },
  methods: {
    async onEditPassword(input, updateUserAccount) {
      await updateUserAccount(input);
      this.$refs.editPassword.resetFields();
    }
  }
};
</script>
