<template>
  <el-form
    ref="contactForm"
    class="edit-contact-form"
    :disabled="isLoading"
    :rules="rules"
    label-position="top"
    :model="contactForm"
  >
    <div class="flex flex-row mt-6">
      <custom-el-form-item
        :is-loading="isLoading"
        label-class="font-semibold"
        class="w-1/2 mr-3"
        label="First Name"
        prop="first_name"
      >
        <el-input
          v-model="contactForm.first_name"
          placeholder="First Name"
          @input="$emit('input', contactForm)"
        />
      </custom-el-form-item>
      <custom-el-form-item
        :is-loading="isLoading"
        label-class="font-semibold"
        class="w-1/2"
        label="Last Name"
        prop="last_name"
      >
        <el-input
          v-model="contactForm.last_name"
          placeholder="Last Name"
          @input="$emit('input', contactForm)"
        />
      </custom-el-form-item>
    </div>
    <div class="flex flex-row mt-4">
      <custom-el-form-item
        ref="userEmail"
        :is-loading="isLoading"
        label-class="font-semibold"
        class="w-1/2 mr-3"
        label="Email"
        prop="email"
      >
        <el-input
          v-model="contactForm.email"
          placeholder="Email"
          @input="$emit('input', contactForm)"
        />
      </custom-el-form-item>
      <custom-el-form-item
        :is-loading="isLoading"
        label-class="font-semibold"
        class="w-1/2"
        label="Phone"
        prop="phone"
      >
        <el-input
          v-model="contactForm.phone"
          v-mask.phone
          placeholder="Phone Number"
          @change="$emit('input', contactForm)"
        />
      </custom-el-form-item>
    </div>

    <div v-if="$slots.bottom" class="edit-contact-form-bottom">
      <slot name="bottom" />
    </div>

    <div v-if="showSubmit" class="edit-contact-form-submit">
      <slot name="submit">
        <el-button
          v-if="!isLoading"
          :disabled="!hasChanges"
          class="button-blue w-full mt-8"
          @click="onSubmit"
        >
          Save Changes
        </el-button>
        <loading-button v-else class="w-full mt-8" />
      </slot>
    </div>
  </el-form>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import { infoCircled } from '@/vendor/icons';
import { isEqual } from 'lodash';
import { LoadingButton } from '@/components/Core/Loading';
import { validEmail } from '@/utils/filters';

export default {
  components: {
    CustomElFormItem,
    LoadingButton
  },
  model: {
    prop: 'contact',
    event: 'input'
  },
  props: {
    customRules: {
      type: Object,
      default: null
    },
    isLoading: Boolean,
    showSubmit: Boolean,
    contact: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      contactForm: this.getContactDefaults(),
      rules: this.customRules || {
        first_name: [
          { required: true, message: 'First name required', trigger: 'blur' }
        ],
        last_name: [
          { required: false, message: 'Last name required', trigger: 'blur' }
        ],
        email: [{ validator: validEmail, trigger: 'blur' }],
        phone: [
          {
            required: false,
            message: 'Please input a phone number',
            trigger: 'blur'
          }
        ]
      },

      // Icons
      infoCircled
    };
  },
  computed: {
    hasChanges() {
      return !isEqual(this.contactForm, this.getContactDefaults());
    }
  },
  watch: {
    contact() {
      this.resetForm();
      this.$refs.contactForm.clearValidate();
    }
  },
  methods: {
    getContactDefaults() {
      return {
        first_name: this.contact ? this.contact.first_name : '',
        last_name: this.contact ? this.contact.last_name : '',
        email: this.contact ? this.contact.email : '',
        phone: this.contact ? this.contact.phone : ''
      };
    },
    resetForm() {
      this.contactForm = this.getContactDefaults();
    },
    validate(callback) {
      this.$refs.contactForm.validate(callback);
    },
    onSubmit() {
      this.validate(valid => {
        if (valid) {
          this.$emit('submit', this.contactForm);
        }
      });
    }
  }
};
</script>
