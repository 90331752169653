<template>
  <div>
    <h3 class="text-md mb-6 font-semibold">PENDING</h3>
    <div v-if="isLoading">
      <loader-box v-for="index in 4" :key="index" height="4em" class="py-3" />
    </div>
    <div class="flex flex-wrap">
      <card
        v-for="action in pendingActions"
        :key="`pending-action-card-${action.id}`"
        class="p-4 mb-8 cursor-pointer hover:bg-light-gray p-6 mr-6 flex-1"
        @click="onClickCard(action)"
      >
        <div class="flex flex-row h-full items-center">
          <div class="flex border-right h-full pr-4 items-center">
            <h1 class="text-4xl font-bold">{{ action.total }}</h1>
          </div>
          <div class="flex flex-col pl-4 h-full justify-between">
            <h2>{{ SummaryStatInfo[action.summary_type].title }}</h2>
            <div
              v-if="action.subTotals"
              class="flex text-dark-silver text-md mt-2"
            >
              {{ subTotalText(action.subTotals) }}
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Core/Card';

import { SummaryStatInfo } from '@/constants';

export default {
  components: {
    Card
  },
  props: {
    isLoading: Boolean,
    pendingActions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      SummaryStatInfo
    };
  },
  methods: {
    onClickCard(action) {
      const actionInfo = SummaryStatInfo[action.summary_type];
      this.$router.push({
        name: actionInfo.nextRoute,
        query: { filter: action.filter }
      });
    },
    subTotalText(totals) {
      let text = '';
      totals.forEach((total, index) => {
        text += `${total.title}: ${total.value}`;
        text += index < totals.length - 1 ? '\xa0\xa0•\xa0\xa0' : '';
      });
      return text;
    }
  }
};
</script>
